<template>
  <base-section
    id="cop"
    space="36"
  >
    <v-spacer />
    <div v-if="$route.name=='Cop'">
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>
      <base-section-heading title="COP(Cleaning Out Place)란?" />
      <v-container
      class="text-center"
      >
        COP(Cleaning Out Place) 세척제는 식품공장 기기 외부 및 작업장용 다목적 세제입니다. <br>
        살균과 세척이 동시에 가능하며 미생물에 취약한 식품 공장의 기구 외부 세척에 효과가 탁월 합니다.
        <br><br>
        사용농도에서 금속 부식성이 없을 뿐만 아니라 인체의 자극이 적습니다. <br>
        특히 Foam Type의 경우 전용 Foam Cleaner를 이용할 경우 별도의 물리적 작업이 필요없이 거품을 세척하고자 하는 곳에 도포하여 <br>
        수분간 방치 후 물로 행굼으로써 식품 공장 기기 외부 및 작업장을 청결하게 유지할 수 있습니다.
        <br><br>
        작업이 용이할 뿐만 아니라 작업 시간이 단축되어 수작업의 청소가 고압 세척에 비하여 경비절감 효과가 있습니다.
        <br><br>
      </v-container>
      <v-container>
      </v-container>

      <v-container>
      </v-container>
      <base-section-heading title="제품군" />
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                다목적 세척제
              </v-subheader>
            </v-card>
          </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                알카리 거품세척제(고기포 저압 세척기용)
              </v-subheader>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card>
              <v-subheader
              :inset="inset"
              >
                산성 거품세척제(고기포 저압 세척기용)
              </v-subheader>
            </v-card>
        </v-col>
  </base-section>
</template>

<script>
  export default {
    name: 'Sectioncop',
    data: () => ({
      inset: false,
      drawer: null,
      items: [
        'Cip',
        'Cop',
        '세병첨가제',
        '컨베이어 윤활제',
        '생수공장용',
        '수질용',
      ],
    }),

    provide: {
      theme: { isDark: false },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
<style scoped>
.v-subheader {
    font-size: 18px;
    color: coral !important;
}
</style>
